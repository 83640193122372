@tailwind base;

@tailwind components;

@tailwind utilities;

html {
  --wide-container: 60rem;
  --narrow-container: 40rem;

  --font-family-sans: "Open Sans", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";

  --font-family-rig: "rig-shaded-bold-face", "Open Sans", ui-sans-serif,
    system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  --color-black: #202123;
  --color-dark-gray: #32373e;
  --color-gray: #697a90;
  --color-light-gray: #b4bcc7;
  --color-very-light-gray: #e7ebed;
  --color-white: #fff;
  --color-accent: #156dff;

  /* Typography */
  --unit: 16;
  --font-micro-size: calc(10 / var(--unit) * 1rem); /* 10px */
  --font-micro-line-height: calc(12 / 10); /* 12px */
  --font-small-size: calc(14 / var(--unit) * 1rem); /* 14px */
  --font-small-line-height: calc(21 / 14); /* 21px */
  --font-base-size: 1em; /* 16px */
  --font-base-line-height: calc(24 / var(--unit)); /* 24px */
  --font-large-size: calc(18 / var(--unit) * 1rem); /* 18px */
  --font-large-line-height: calc(27 / 18); /* 27px */

  --font-title3-size: calc(21 / var(--unit) * 1rem); /* 21px */
  --font-title3-line-height: calc(30 / 21); /* 30px */
  --font-title2-size: calc(24 / var(--unit) * 1rem); /* 24px */
  --font-title2-line-height: calc(33 / 24); /* 33px */
  --font-title1-size: calc(49 / var(--unit) * 1rem); /* 49px */
  --font-title1-line-height: calc(57 / 49); /* 57px */

  /* Widths */
  --width-x-small: 20em;
  --width-small: 40em;
  --width-medium: 60em;
  --width-large: 80rem;
  --width-x-large: 100em;

  font-family: var(--font-family-sans);
  font-size: var(--font-base-size);
  line-height: var(--font-base-line-height);
}

@font-face {
  font-family: "wildstripe";
  font-style: normal;
  font-weight: normal;
  src: url("../../public/fonts/wildstripe.woff") format("woff");
}

body {
  -webkit-font-smoothing: antialiased;
  background: var(--color-white);
  color: var(--color-black);
  margin: 0;
}

html,
body,
:global(#__next),
:global(.old-container) {
  height: 100%;
}

:global(.content) {
  min-height: calc(100% - 84px - 218px);
}

.lock-scroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-rig);
}
